<template>
  <section
    style="background: #121212; height: 100vh; width: 100vw; overflow: hidden"
  >
    <v-row class="fill-height" justify="center" align="center">
      <v-col cols="12" md="6">
        <v-card
          color="transparent"
          flat
          max-width="200"
          class="mx-auto d-flex flex-column justify-center"
        >
          <v-icon size="120" color="info">mdi-video</v-icon>
          <v-btn text color="#fff" @click="meetings">
            <v-icon left>mdi-arrow-left</v-icon>
            Go back to meetings
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  methods: {
    meetings() {
      window.parent.location.href = "/admin/meetings";
    },
  },
};
</script>